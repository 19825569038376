import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../index.css';

const Mission = () => {
    
    return (
        <Container fluid className="bg-dark">
            <Row className="text-center bg-dark mt-4 mb-4">
                <Col>
                    <Row className="d-flex justify-content-center titles mt-4 mb-4 text-center text-white">
                        Our Mission
                    </Row>
                    <Row className="d-flex justify-content-center body mt-4 mb-4 text-center text-white">
                        <p>Fellowship. Gathering. Joy. Delicious Food. Comfort.</p>
                        <p>We want to be included in your weddings, bridal showers, birthdays, baby showers, reunions, and all events that make you feel at home.</p>
                    </Row>
                    <Row className="d-flex justify-content-center mission-verse mt-4 mb-4 text-center text-white">
                        <p>"Gracious words are like a honeycomb, sweetness to the soul and health to the body." Proverbs 16:24</p>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Mission;
