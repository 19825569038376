import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import honeyandhome from '../../assets/honeyandhome.jpg';
import '../../index.css';

const Details = () => {
    
    return (
        <Container fluid>
            <Row className="d-flex align-items-center justify-content-center mt-4 mb-4">
                <Col className="text-center" xs={12} sm={12} md={12} lg={6} xl={6} xxl={3}>
                    <Row className="d-flex justify-content-center titles mt-4 mb-4 text-center">
                        The Details
                    </Row>
                    <Row className="d-flex justify-content-center body mt-4 mb-4 text-center">
                        <p>We care about your event just as much as you do. That is why we purchase only the most delicious ingredients, input your preferences into each board, and put nothing on the board that you do not already know about.</p>
                        <p> We want our boards to make you feel like home, and that is why we handcraft them perfectly for you, and you alone.</p>
                    </Row>
                </Col>
                <Col className="text-center" xs={12} sm={12} md={12} lg={6} xl={6} xxl={3}>
                    <Image className="logo-img" fluid rounded src={honeyandhome}/>
                </Col>
            </Row>
        </Container>
    );
}
  
export default Details;
