import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../index.css';

const Process = () => {
    
    return (
        <Container fluid className="mt-4">
            <Container className="mt-4">
                <Row className="text-center">
                    <Col className="px-5">
                        <Row className="d-flex justify-content-center titles mb-4 text-center">
                            Our Order Process
                        </Row>
                        <Row className="d-flex justify-content-center body mb-4 text-center">
                            We aim to make our ordering process as simple as possible.
                        </Row>
                        <Row className="d-flex justify-content-center body mb-4 text-center">
                            Once you've decided which board you'd like, make the selection below. You will be directed to an order form. Please fill out your information as well as any personalization details you'd like for us to include on your board.
                        </Row>
                        <Row className="d-flex justify-content-center body mb-4 text-center">
                            We will reach out to you within 24 hours of your order placement to collect more information and set up a time and location for delivery. No need to worry about payment for now, we will handle payment details at the time of delivery.
                        </Row>
                        <Row className="d-flex justify-content-center body mb-4 text-center">
                            We can't wait to build your perfect board!
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
  
export default Process;
