import React from 'react';
import Info from './Info';
import Details from './Details';
import Title from '../global/Title';

const About = () => {
    
    return (
        <div className="content-container">
            <Title title={'About Us'}/>
            <Info />
            <Details />
        </div>
    );
}

export default About;
