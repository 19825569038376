import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AiOutlineCopyright } from 'react-icons/ai';
import { AiFillHome } from 'react-icons/ai';
import { GrMail } from 'react-icons/gr';
import { ImPhone } from 'react-icons/im';
import { AiOutlineInstagram } from 'react-icons/ai';
import '../../index.css';

function Footer() {

    return (
        <footer>
            <Container fluid className="mt-4 bg-dark footer-pin">
                <Container className="bg-dark">
                    <Row className="d-flex justify-content-center">
                        <Col className="text-center text-white mt-4 mb-4" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                            <p className="text-muted"><strong>HONEY & HOME BOARDS, LLC.</strong></p>
                            <p className="text-muted">Thank you so much for visiting the Honey & Home Boards website. We look forward to the opportunity to work with you soon.</p> 
                        </Col>
                        <Col className="text-center text-white mt-4 mb-4" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                            <p className="text-muted"><strong>BOARD SELECTIONS</strong></p>
                            <p><Link className="footer-link text-muted" to={'/order'} state={'Greet'} onClick={() => window.scrollTo(0,0)}>Greet</Link></p>
                            <p><Link className="footer-link text-muted" to={'/order'} state={'Grow'} onClick={() => window.scrollTo(0,0)}>Grow</Link></p>
                            <p><Link className="footer-link text-muted" to={'/order'} state={'Gather'} onClick={() => window.scrollTo(0,0)}>Gather</Link></p>
                        </Col>
                        <Col className="text-center text-white mt-4 mb-4" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                            <p className="text-muted"><strong>USEFUL LINKS</strong></p>
                            <p><Link className="footer-link text-muted" to={'/order'} onClick={() => window.scrollTo(0,0)}>Order</Link></p>
                            <p><Link className="footer-link text-muted" to={'/gallery'} onClick={() => window.scrollTo(0,0)}>Gallery</Link></p>
                            <p><Link className="footer-link text-muted" to={'/about'} onClick={() => window.scrollTo(0,0)}>About</Link></p>
                            <p><Link className="footer-link text-muted" to={'/contact'} onClick={() => window.scrollTo(0,0)}>Contact</Link></p>
                        </Col>
                        <Col className="text-center text-white mt-4 mb-4" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                            <p className="text-muted"><strong>CONTACT</strong></p>
                            <p className="text-muted"><AiFillHome /> Virginia Beach, VA</p>
                            <p className="text-muted"><ImPhone /> 804.955.0395</p>
                            <p>
                                <a className="footer-link text-muted" href={"mailto:honeyandhomeboards@gmail.com"}>
                                    <GrMail /> honeyandhomeboards@gmail.com
                                </a>
                            </p>
                            <div>
                                <Link className="footer-media text-muted" to={'https://www.instagram.com/honeyandhomeboards/?igshid=YmMyMTA2M2Y%3D'} target={"_blank"}>
                                    <AiOutlineInstagram />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center mb-4">
                            <AiOutlineCopyright /> 2023 Honey & Home Boards, LLC.
                        </Col>
                    </Row>
                </Container>
            </Container>
        </footer>
    );
}

export default Footer;
