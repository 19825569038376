import React from 'react';
import { useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert'
import '../../index.css';

const SuccessContact = () => {

    const location = useLocation();

    return (
        <div className="content-container">
            <Container className="mt-4 mb-4">
                <Alert variant="primary">
                    <Alert.Heading>Thanks for your question, {location.state.first_name}!</Alert.Heading>
                    <p>We have received your question and will get back to you as soon as possible.</p>
                    <hr />
                    <p className="mb-0">If you have any questions between now and then, please <Alert.Link href="/contact">contact us</Alert.Link>.</p>
                </Alert>
            </Container>
        </div>
    );
}
  
export default SuccessContact;
