import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import LoadingOverlay from 'react-loading-overlay-ts';
import Title from '../global/Title';
import * as yup from "yup";
import { Formik } from "formik";
import '../../index.css';

const schema = yup.object().shape({
    first_name: yup.string().required("Please provide a first name"),
    last_name: yup.string().required("Please provide a last name"),
    email: yup.string().email().required("Please provide a valid email address"),
    comment: yup.string().required("Please provide a question or comment"),
});

const Contact = () => {

    let API_URL = `https://suc61fvatj.execute-api.us-east-1.amazonaws.com/production/`;

    const loadingRef = useRef()
    const executeScroll = () => loadingRef.current.scrollIntoView({
        block: "center",
        inline: "center",
    });

    const navigate = useNavigate();

    let [submitting, setSubmitting] = useState(false);

    let submitForm = async (values) => {
        setSubmitting(true);
        await fetch(API_URL += `comments/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        }).then(response => {
            if (response.ok) {
                response.json().then(comment => {
                    if (comment.statusCode === 200) {
                        navigate('/contact/success/', {state:{first_name: comment.first_name}});
                    } else {
                        navigate('/error');
                    }
                });
            } else {
                navigate('/error');
            }
        });
    }

    return (
        <div className="content-container">
            <Title title={'Get in touch with us!'}/>
            <Container className="mb-4">
                <Formik
                    validationSchema={schema}
                    onSubmit={submitForm}
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        comment: '',
                    }}
                    >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <LoadingOverlay
                                active={submitting}
                                spinner
                                styles={{
                                    overlay: (base) => ({...base, background: 'rgba(0, 0, 0, 0)'}),
                                    spinner: (base) => ({
                                        ...base,
                                        width: '75px',
                                        '& svg circle': {
                                        stroke: 'rgba(0, 0, 0, 1)'
                                }})
                            }}
                            >
                        <div ref={loadingRef}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="first_name">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="First name"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        isInvalid={touched.first_name && !!errors.first_name}
                                        isValid={touched.first_name && !errors.first_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.first_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="last_name">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Last name"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        isInvalid={touched.last_name && !!errors.last_name}
                                        isValid={touched.last_name && !errors.last_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.last_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="12" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email address"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={touched.email && !!errors.email}
                                        isValid={touched.email && !errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="comment">
                                    <Form.Label>Comments</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Please explain..."
                                            name="comment"
                                            value={values.comment}
                                            onChange={handleChange}
                                            isInvalid={touched.comment && !!errors.comment}
                                            isValid={touched.comment && !errors.comment}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.comment}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <Button onClick = {() => executeScroll()} variant="success" type="submit">Submit</Button>
                        </Form>
                        </div>
                        </LoadingOverlay>
                    )}
                </Formik>
            </Container>
        </div>
    );
}

export default Contact;
