import React from 'react';
import Options from './Options';
import Process from './Process';

const Order = () => {

    return (
        <div className="content-container">
            <Process />
            <Options />
        </div>
    );
}

export default Order;
