import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import image from '../../assets/image.jpg';
import '../../index.css';

const Info = () => {
    
    return (
        <Container fluid>
            <Row className="d-flex align-items-center justify-content-center mt-4 mb-4">
                <Col className="text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                    <Image className="about-img" fluid rounded src={image}/>
                </Col>
                <Col className="text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                    <Row className="d-flex justify-content-center titles mt-4 text-center">
                        Sloan Lambert
                    </Row>
                    <Row className="d-flex justify-content-center mb-4 text-center text-muted">
                        Our Owner and Operator
                    </Row>
                    <Row className="d-flex justify-content-center body mt-4 text-center">
                        <p>Hi there, everyone! I'm so happy you're here.</p>
                        <p>My name is Sloan Lambert. I just got married in June to my husband, Kalub. I am a third-year student in law school.</p>
                        <p>I have always enjoyed events, planning events, delicious food, and fellowship. Combining these things that led me to this venture that I have always dreamed of.</p>
                        <p>I cannot wait to work with you!</p>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Info;
