import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import greet from '../../assets/greet.jpg';
import grow from '../../assets/grow.jpg';
import gather from '../../assets/gather.jpg';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import '../../index.css';

const Body = () => {

  const navigate = useNavigate();
  
  return (
    <Container fluid bg="dark" variant="dark">
      <Row className="d-flex justify-content-center align-items-center mt-4 mb-4">
        <Col className="px-5 py-3 text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
          <Card bg="dark" variant="dark">
            <Card.Img onClick={() => navigate('/order')} style={{ cursor: "pointer" }} variant="top" src={greet} />
            <Card.Body>
            <Card.Title className="text-white">Greet</Card.Title>
            <Card.Text className="text-white">
              One cheese and one meat of your choice with a small assortment of cherry tomatoes, peppers, nuts, chocolate, and crackers. Can be personalized.
            </Card.Text>
            </Card.Body>
            <Card.Body>
            <Link to={'/checkout'}>
              <Button className="mb-2" variant="outline-light" size="lg">Order Now</Button>
            </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col className="px-5 py-3 text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
          <Card bg="dark" variant="dark">
            <Card.Img onClick={() => navigate('/order')} style={{ cursor: "pointer" }} variant="top" src={grow} />
            <Card.Body>
            <Card.Title className="text-white">Grow</Card.Title>
            <Card.Text className="text-white">
                Two cheeses and two meats of your choice with a medium assortment of cherry tomatoes, peppers, nuts, chocolate, and crackers. Can be personalized.
            </Card.Text>
            </Card.Body>
            <Card.Body>
            <Link to={'/checkout'}>
              <Button className="mb-2" variant="outline-light" size="lg">Order Now</Button>
            </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col className="px-5 py-3 text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
          <Card bg="dark" variant="dark">
            <Card.Img onClick={() => navigate('/order')} style={{ cursor: "pointer" }} variant="top" src={gather} />
            <Card.Body>
            <Card.Title className="text-white">Gather</Card.Title>
            <Card.Text className="text-white">
                Three cheeses and three meats of your choice with a large assortment of cherry tomates, peppers, buts, chocolate, and crackers. Can be personalized.
            </Card.Text>
            </Card.Body>
            <Card.Body>
            <Link to={'/checkout'}>
              <Button className="mb-2" variant="outline-light" size="lg">Order Now</Button>
            </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Body;
