import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { HiOutlineCake } from 'react-icons/hi';
import { BiDrink } from 'react-icons/bi';
import { FiHeart } from 'react-icons/fi';
import { TbBabyCarriage } from 'react-icons/tb';
import '../../index.css';

const Titles = () => {

    return (
        <Container fluid>
            <Row className="titles d-flex align-items-center justify-content-center">
                <Col className="px-5 text-center">
                    <div>Weddings</div>
                    <div className="title-icon mb-3">
                        <FiHeart />
                    </div>
                </Col>
                <Col className="px-5 text-center">
                    <div>Showers</div>
                    <div className="title-icon mb-3">
                        <TbBabyCarriage />
                    </div>
                </Col>
                <Col className="px-5 text-center">
                    <div>Birthdays</div>
                    <div className="title-icon mb-3">
                        <HiOutlineCake />
                    </div>
                </Col>
                <Col className="px-5 text-center">
                    <div>Celebrations</div>
                    <div className="title-icon mb-3">
                        <BiDrink />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Titles;
