import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import SuccessCheckout from './pages/checkout/SuccessCheckout';
import SuccessContact from './pages/contact/SuccessContact';
import Checkout from './pages/checkout/Checkout';
import Gallery from './pages/gallery/Gallery';
import Order from './pages/order/Order';
import Error from './pages/error/Error';
import Notfound from './pages/notfound/Notfound';
import Navigation from './pages/global/Navigation';
import Footer from './pages/global/Footer';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <div className="bg-light">
      <BrowserRouter>
          <Navigation/>
            <ScrollToTop />
            <Routes>              
              <Route path="/" element={<Home/>}/>
              <Route path="/about" element={<About/>}/> 
              <Route path="/contact" element={<Contact/>}/> 
              <Route path="/gallery" element={<Gallery/>}/>
              <Route path="/order" element={<Order/>}/>
              <Route path="/checkout" element={<Checkout/>}/>
              <Route path="/checkout/success/" element={<SuccessCheckout/>}/>
              <Route path="/contact/success" element={<SuccessContact/>}/>
              <Route path="/error" element={<Error/>}/>
              <Route path='*' element={<Notfound />}/>
            </Routes>
          <Footer/>
      </BrowserRouter>
    </div>
  )
}

export default App
