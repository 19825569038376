import React from 'react';
import { useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert'
import '../../index.css';

const SuccessCheckout = () => {

  const location = useLocation();

  return (
      <div className="content-container">
        <Container className="mt-4 mb-4">
          <Alert variant="success">
            <Alert.Heading>Thanks for your order, {location.state.first_name}!</Alert.Heading>
            <p>We have received your order and will reach out to you within 24 hours.</p>
            <hr />
            <p className="mb-0">If you have any questions between now and then, please <Alert.Link href="/contact">contact us</Alert.Link>.</p>
          </Alert>
        </Container>
      </div>
  );
}

export default SuccessCheckout;
