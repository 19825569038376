import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../index.css';

const Title = (props) => {

    return (
        <Container fluid className="mt-4">
            <Row className="d-flex justify-content-center align-items-center">
                <Col>
                    <Row className="titles">
                        <p className="text-center">
                            {props.title}
                        </p>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Title;
