import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { MdArrowForward } from 'react-icons/md';
import '../../index.css';

const Header = () => {
    
    return (
        <Container fluid>
            <Row className="text-center mt-4 mb-4">
                <Col className="px-5">
                    <Row className="d-flex justify-content-center header-title mb-4 text-center">
                        Honey & Home Boards
                    </Row>
                    <Row className="d-flex justify-content-center header-body mb-4 text-center">
                        Let us be a part of your next celebration.
                    </Row>
                    <Button className="px-5" href="/order" variant="success" size="sm">Order <MdArrowForward/></Button>
                </Col>
            </Row>
        </Container>
    );
}
  
export default Header;
