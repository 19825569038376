import React, { useState, useEffect } from 'react';
import Titles from './Titles';
import Body from './Body';
import Header from './Header';
import Mission from './Mission';
import Boards from './Boards';
import Testimonials from './Testimonials';

const Home = () => {

    const [isDesktop, setDesktop] = useState(false);

    useEffect(() => {
      if (window.innerWidth > 1150) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
  
      const updateMedia = () => {
        if (window.innerWidth > 1150) {
          setDesktop(true);
        } else {
          setDesktop(false);
        }
      };
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }, []);
    
    return (
        <div className="content-container">
            {isDesktop ? (
                <>
                <Header />
                <Titles />
                <Body />
                <Mission />
                <Boards />
                <Testimonials />
                </>
            ) : (
                <>
                <Header />
                <Body />
                <Titles />
                <Mission />
                <Boards />
                <Testimonials />
                </>
            )}
        </div>
    );
}

export default Home;
