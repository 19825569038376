import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import testimonials from '../../assets/testimonials.jpg';
import '../../index.css';

const Testimonials = () => {
    
    return (
        <Container fluid className="py-2">
            <Row className="d-flex align-items-center justify-content-center mb-4">
                <Col className="text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                    <Row className="d-flex justify-content-center titles mt-4 mb-4 text-center">
                        Testimonials
                    </Row>
                    <Row className="d-flex justify-content-center body mt-4 mb-4 text-center">
                        <p>
                            "My bridal shower was even more special because of Honey and Home. 
                            We wanted a board of only my favorite things, and Sloan was more than willing to comply. Book with her!"
                        </p>
                        <p>
                            <strong>- Abigail Hayes</strong>
                        </p>
                    </Row>
                    <Row className="d-flex justify-content-center body mt-4 mb-4 text-center">
                        <p>
                            "Not only was Sloan a joy to work with, but this was 
                            the most beautiful part of the party. I cannot wait to book more."
                        </p>
                        <p>
                        <strong>- Alyssa Dahl</strong>
                        </p>
                    </Row>
                </Col>
                <Col className="text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                    <Image className="about-img" fluid rounded src={testimonials}/>
                </Col>
            </Row>
        </Container>
    );
}
  
export default Testimonials;
