import React, { useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import LoadingOverlay from 'react-loading-overlay-ts';
import * as yup from "yup";
import { Formik } from "formik";
import '../../index.css';

const schema = yup.object().shape({
    board: yup.string().required("Please select a board"),
    first_name: yup.string().required("Please provide a first name"),
    last_name: yup.string().required("Please provide a last name"),
    email: yup.string().email().required("Please provide a valid email address"),
    phone_number: yup.string().required("Please provide a valid phone number"),
    address_one: yup.string().required("Please provide an address"),
    address_two: yup.string(),
    city: yup.string().required("Please provide a city"),
    state: yup.string().required("Please select a state"),
    zipcode: yup.string().required("Please provide a valid zip code"),
    additional_details: yup.string(),
    // terms: yup.bool().required().oneOf([true], 'Must accept terms and conditions'),
});

const Checkout = () => {

    let API_URL = `https://suc61fvatj.execute-api.us-east-1.amazonaws.com/production/`;

    const loadingRef = useRef()
    const executeScroll = () => loadingRef.current.scrollIntoView({
        block: "center",
        inline: "center",
    });

    const navigate = useNavigate();

    let [submitting, setSubmitting] = useState(false);

    let submitForm = async (values) => {
        setSubmitting(true);
        await fetch(API_URL += `orders/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        }).then(response => {
            if (response.ok) {
                response.json().then(order => {
                    if (order.statusCode === 200) {
                        navigate('/checkout/success/', {state:{first_name: order.first_name}});
                    } else {
                        navigate('/error');
                    }
                });
            } else {
                navigate('/error');
            }
        });
    }

    return (
        <div className="content-container">  
            <Container className="mt-4 mb-4">
            <p className="text-center body mb-4">
                Please fill out the form below to order a board. For additional information about our order process, please visit our <strong><Link className="text-link" to={'/order'}>order</Link></strong> page.
            </p>
                <Formik
                    validationSchema={schema}
                    onSubmit={submitForm}
                    initialValues={{
                        board: '',
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone_number: '',
                        address_one: '',
                        address_two: '',
                        city: '',
                        state: '',
                        zipcode: '',
                        additional_details: '',
                        terms: false,
                    }}
                    >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                    }) => (
                        <div ref={loadingRef}>
                        <LoadingOverlay
                            active={submitting}
                            spinner
                            styles={{
                                overlay: (base) => ({...base, background: 'rgba(0, 0, 0, 0)'}),
                                spinner: (base) => ({
                                    ...base,
                                    width: '75px',
                                    '& svg circle': {
                                    stroke: 'rgba(0, 0, 0, 1)'
                            }})
                        }}
                        >
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="board">
                                    <Form.Label>Board Selection</Form.Label>
                                    <div className="mb-3 mt-2">
                                        <Form.Check
                                            inline
                                            label="Greet"
                                            name="board"
                                            type="radio"
                                            value="Greet"
                                            id="Greet"
                                            onChange={handleChange}
                                            isInvalid={touched.board && !!errors.board}
                                            isValid={touched.board && !errors.board}
                                        />
                                        <Form.Check
                                            inline
                                            label="Grow"
                                            name="board"
                                            type="radio"
                                            value="Grow"
                                            id="Grow"
                                            onChange={handleChange}
                                            isInvalid={touched.board && !!errors.board}
                                            isValid={touched.board && !errors.board}
                                        />
                                        <Form.Check
                                            inline
                                            label="Gather"
                                            name="board"
                                            type="radio"
                                            value="Gather"
                                            id="Gather"
                                            onChange={handleChange}
                                            isInvalid={touched.board && !!errors.board}
                                            isValid={touched.board && !errors.board}
                                        />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.board}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="first_name">
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="First name"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                        isInvalid={touched.first_name && !!errors.first_name}
                                        isValid={touched.first_name && !errors.first_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.first_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="last_name">
                                    <Form.Label>Last name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Last name"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        isInvalid={touched.last_name && !!errors.last_name}
                                        isValid={touched.last_name && !errors.last_name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.last_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="email">
                                    <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                        type="email"
                                        placeholder="Email address"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={touched.email && !!errors.email}
                                        isValid={touched.email && !errors.email}
                                        />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="phone_number">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Phone number"
                                        name="phone_number"
                                        value={values.phone_number}
                                        onChange={handleChange}
                                        isInvalid={touched.phone_number && !!errors.phone_number}
                                        isValid={touched.phone_number && !errors.phone_number}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone_number}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="12" controlId="address_one">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                    type="text"
                                    placeholder="Address line one"
                                    name="address_one"
                                    value={values.address_one}
                                    onChange={handleChange}
                                    isInvalid={touched.address_one && !!errors.address_one}
                                    isValid={touched.address_one && !errors.address_one}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address_one}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="address_two">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                    type="text"
                                    placeholder="Address line two"
                                    name="address_two"
                                    value={values.address_two}
                                    onChange={handleChange}
                                    isInvalid={touched.address_two && !!errors.address_two}
                                    isValid={touched.address_two && !errors.address_two}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="City"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        isInvalid={touched.city && !!errors.city}
                                        isValid={touched.city && !errors.city}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select 
                                        aria-label="state"
                                        name="state"
                                        value={values.state}
                                        onChange={handleChange}
                                        isInvalid={touched.state && !!errors.state}
                                        isValid={touched.firstName && !errors.firstName}
                                    >
                                        <option selected disabled value="">Choose...</option>
                                        <option value="AL"> Alabama</option>
                                        <option value="AK"> Alaska</option>
                                        <option value="AZ"> Arizona</option>
                                        <option value="AR"> Arkansas</option>
                                        <option value="CA"> California</option>
                                        <option value="CO"> Colorado</option>
                                        <option value="CT"> Connecticut</option>
                                        <option value="DE"> Delaware</option>
                                        <option value="DC"> District of Columbia</option>
                                        <option value="FL"> Florida</option>
                                        <option value="GA"> Georgia</option>
                                        <option value="HI"> Hawaii</option>
                                        <option value="ID"> Idaho</option>
                                        <option value="IL"> Illinois</option>
                                        <option value="IN"> Indiana</option>
                                        <option value="IA"> Iowa</option>
                                        <option value="KS"> Kansas</option>
                                        <option value="KY"> Kentucky</option>
                                        <option value="LA"> Louisiana</option>
                                        <option value="ME"> Maine</option>
                                        <option value="MD"> Maryland</option>
                                        <option value="MA"> Massachusetts</option>
                                        <option value="MI"> Michigan</option>
                                        <option value="MN"> Minnesota</option>
                                        <option value="MS"> Mississippi</option>
                                        <option value="MO"> Missouri</option>
                                        <option value="MT"> Montana</option>
                                        <option value="NE"> Nebraska</option>
                                        <option value="NV"> Nevada</option>
                                        <option value="NH"> New Hampshire</option>
                                        <option value="NJ"> New Jersey</option>
                                        <option value="NM"> New Mexico</option>
                                        <option value="NY"> New York</option>
                                        <option value="NC"> North Carolina</option>
                                        <option value="ND"> North Dakota</option>
                                        <option value="OH"> Ohio</option>
                                        <option value="OK"> Oklahoma</option>
                                        <option value="OR"> Oregon</option>
                                        <option value="PA"> Pennsylvania</option>
                                        <option value="RI"> Rhode Island</option>
                                        <option value="SC"> South Carolina</option>
                                        <option value="SD"> South Dakota</option>
                                        <option value="TN"> Tennessee</option>
                                        <option value="TX"> Texas</option>
                                        <option value="UT"> Utah</option>
                                        <option value="VT"> Vermont</option>
                                        <option value="VA"> Virginia</option>
                                        <option value="WA"> Washington</option>
                                        <option value="WV"> West Virginia</option>
                                        <option value="WI"> Wisconsin</option>
                                        <option value="WY"> Wyoming</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.state}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="zipcode">
                                    <Form.Label>Zip</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Zip"
                                        name="zipcode"
                                        value={values.zipcode}
                                        onChange={handleChange}
                                        isInvalid={touched.zipcode && !!errors.zipcode}
                                        isValid={touched.zipcode && !errors.zipcode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.zipcode}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="additional_details">
                                    <Form.Label>Want to personalize your board? Tell us how!</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Please explain..."
                                                name="additional_details"
                                                value={values.additional_details}
                                                onChange={handleChange}
                                                isInvalid={touched.additional_details && !!errors.additional_details}
                                                isValid={touched.additiona_details && !errors.additional_details}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.additional_details}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                </Form.Group>
                            </Row>
                                {/* <Form.Group className="mb-3">
                                <Form.Check
                                required
                                name="terms"
                                label="Agree to terms and conditions"
                                onChange={handleChange}
                                isInvalid={touched.terms && !!errors.terms}
                                isValid={touched.terms && !errors.terms}
                                feedback={errors.terms}
                                feedbackType="invalid"
                                id="terms"
                                />
                                </Form.Group> */}
                            <Button onClick = {() => executeScroll()} variant="success" type="submit">Submit</Button>
                        </Form>
                        </LoadingOverlay>
                        </div>
                    )}
                </Formik>
            </Container>
        </div>
    );
}

export default Checkout;
