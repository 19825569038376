import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Title from '../global/Title';
import one from '../../assets/one.jpg';
import two from '../../assets/two.jpg';
import three from '../../assets/three.jpg';
import four from '../../assets/four.jpg';
import five from '../../assets/five.jpg';
import six from '../../assets/six.jpg';
import seven from '../../assets/seven.jpg';
import eight from '../../assets/eight.jpg';
import nine from '../../assets/nine.jpg';
import ten from '../../assets/ten.jpg';
import eleven from '../../assets/eleven.jpg';
import twelve from '../../assets/twelve.jpg';
import '../../index.css';

const Gallery = () => {

    return (
        <div className="content-container">
            <Title title={'Our Gallery'}/>
            <Container fluid>
                <Row className="d-flex align-items-center justify-content-center">
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={one}/>
                    </Col>
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={two}/>
                    </Col>
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={three}/>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center">
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={four}/>
                    </Col>
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={five}/>
                    </Col>
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={six}/>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center">
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={seven}/>
                    </Col>
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={eight}/>
                    </Col>
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={nine}/>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center">
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={ten}/>
                    </Col>
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={eleven}/>
                    </Col>
                    <Col className="px-3 py-3" xs={12} sm={6} md={6} lg={3}>
                        <Image fluid rounded src={twelve}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Gallery;
