import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import boards from '../../assets/boards.jpg';
import '../../index.css';

const Boards = () => {

    return (
        <Container fluid className="py-3">
            <Row className="d-flex align-items-center justify-content-center mt-4 mb-4">
                <Col className="text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                    <Image className="about-img" fluid rounded src={boards}/>
                </Col>
                <Col className="text-center" xs={12} sm={12} md={6} lg={6} xl={6} xxl={3}>
                    <Row className="d-flex justify-content-center titles mt-4 mb-4 text-center">
                        Our Boards
                    </Row>
                    <Row className="d-flex justify-content-center body mt-4 text-center">
                        <p>
                            We understand that some events will require more food than others. Therefore, we currently offer
                            three charcuterie options. Additionally, we offer the ability to customize your board to your liking.
                        </p>
                        <p>
                            <div className="body">
                                <strong>Small Tray - The Greet</strong>
                            </div>
                            <div className="body">
                                <strong>Medium Tray - The Grow</strong>
                            </div>
                            <div className="body">
                                <strong>Large Tray - The Gather</strong>
                            </div>
                        </p>
                        <p>
                            Starting at $30, we know that our boards will be the talk of your next event. 
                        </p>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
  
export default Boards;
